/* *{
    border: 2px solid red;
} */

.PI-form-layout-work{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns for the first two rows */
    grid-gap: 20px; /* Adjust space between input boxes */
    width: 100%;
    margin-top: 2rem;
}

.PI-form{
    border: 1px solid; /* border */
    border-left-width: 7px; /* border-l-[7px] */
    border-left-color: #4a5a574e; /* border-l-[#01997e] */
    color: grey; /* text-grey */
    width: 100%; /* w-full */
    padding-left: 2rem; /* px-10 */
    padding-right: 2rem;
    padding-top: 0.5rem; /* py-2 */
    padding-bottom: 0.5rem; /* py-2 */
    border-radius: 0.375rem;
  }

  .LMIA-input input{
    max-width:400px;
  }

  .spouse{
    display:flex;
    gap:20px;
    margin-top:15px;
  }

  .wife-status-canada{
    max-width:400px;
    display: flex;
    flex-direction:column;
    align-items:center;
  }

label{
    color:rgba(0, 0, 16, 0.411) !important;
}


.checkbox-container{
    display: flex;
    flex-direction: column;
    margin-top:4px;
    gap: 12px;
}


@media screen and (max-width: 1278px) {
  
    .PI-form-layout-work {
      grid-template-columns: repeat(2, 1fr);
    }
   
  }

  @media  screen and (max-width:670px){
    .PI-form-layout-work {
        grid-template-columns: 1fr;
      }

      .spouse{
        flex-direction:column-reverse;
        gap:0;
      }

      .wife-status-canada{
        max-width:581px;
      }

}