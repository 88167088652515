input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
  border: 1px solid black;
}

.radio-checkbox {
  border-radius: 50%;
  padding:10px;
  border: 1px solid rgba(0, 0, 0, 0.395);
  margin-right: 2rem !important;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #01997e;
  box-shadow: 0px 0px 0px 3px white inset;
}

input[type="radio"]:checked + label {
  color: #01997e;
}
.react-tel-input .flag-dropdown {
  border: 1px solid black !important;
  border-right: none !important;
  border-radius: 0.375rem;
}

@media (max-width: 400px) {
.main-evaluation-form-container{
  padding-left:0 !important;
  padding:4px;
}
}
