
.sideBox-resourceAndTools{
   /* margin-left:0; */
   margin:auto;
}

.sideBox-resourceAndTools-inner{
  width:160px;
}

.exam-guide-inner-container{
    /* background:yellow; */
    margin-bottom:65px;
}
