.book-appointment-form__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 2rem;
  flex-direction: row;
  z-index: 150;
  border-radius: 10px;
}

.book-appointment-form__modal-image {
  width: 35%;
}

.book-appointment-form__modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-appointment-form__form-row-popup {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.book-appointment-form__form-header {
  color: #000;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 15px;
  text-align: center;
  margin-top: -10px;
}

.book-appointment-form__form-group {
  position: relative;
  display: flex !important;
  flex-direction: column;
  flex: 1 !important;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 4px;
}

/* .book-appointment-form__custom-phone-input{
  z-index: 2;
} */

.react-international-phone-country-selector-dropdown{ 
  z-index: 2 !important;
}

#date {
  height: 58px;
}

#srvc {
  height: 58px;
}

.book-appointment-form__form-group label {
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-50%);
  background: white;
  padding: 0 10px;
  font-size: 14px;
  color: #666;
  transition: 0.3s ease;
  pointer-events: none;
  z-index: 1;
}

.book-appointment-form__form-field {
  width: 65%;
  height: 100%;
}

.book-appointment-form__form-fields {
  padding: 0!important;
}

.book-appointment-form__form-group input,
.book-appointment-form__form-group select {
  width: 100%;
  padding: 15px 14px;
  /* border: 2px solid #ccc; */
  /* border-radius: 4px; */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.book-appointment-form__form-row-popup .book-appointment-form__error,
.book-appointment-form__book-date-error {
  border-color: #b90124 !important;
  background-color: #ffe6e6;
}

.book-appointment-form__error-label {
  color: #b90124;
}

.book-appointment-form__form-field .book-appointment-form__error-message {
  text-align: center;
}

.book-appointment-form__Submitbutton {
  background-color: #009889;
  padding: 0.5rem 8rem;
  color: #fff;
  display: block;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  width: 100%;
}

.book-appointment-form__closeIcon {
  position: relative;
  right: 2px;
  display: block;
  margin-top: 0rem;
  align-self: center;
  margin-left: auto;
  margin-right: -1rem;
  font-size: 1.7rem;
  border-radius: 50%;
  justify-self: flex-end;
  cursor: pointer;
  padding: 0.2rem;
}

.book-appointment-form__booking-from-background-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background-color: rgba(30, 30, 30, 0.4);
  backdrop-filter: blur(10px);
}

.book-appointment-form__mobile-image {
  display: none;
}

@media (max-width: 1024px) {
  .book-appointment-form__modal {
    flex-direction: column;
    height: 95vh;
    overflow: auto;
    max-width: 500px;
    width: 95%;
  }

  .book-appointment-form__form-field {
    width: 65%;
  }

  .book-appointment-form__form-row-popup {
    gap: 15px;
  }

  .book-appointment-form__Submitbutton {
    padding: 0.5rem 6rem;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .book-appointment-form__form-header {
    font-size: 1.5rem;
    text-align: center;
  }

  .book-appointment-form__form-field {
    width: 100%;
    height: auto;
    margin-top: -63px;
  }

  .book-appointment-form__form-group input,
  .book-appointment-form__form-group select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    height: 43px;
  }

  .book-appointment-form__custom-phone-input {
    height: 43px;
    font-size: 10px;
    padding: 10px;
    /* border: 2px solid #ccc;
    border-radius: 4px; */
    border: 0px
  }

  .book-appointment-form__form-row-popup {
    flex-direction: column;
  }

  .book-appointment-form__Submitbutton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  .book-appointment-form__thank-you-message {
    font-size: 12px;
  }

  .book-appointment-form__desktop-image {
    display: none;
  }

  .book-appointment-form__modal-image {
    width: 100%;
  }

  .book-appointment-form__mobile-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  .book-appointment-form__mobile-image img {
    width: 100%;
    height: 481px;
    object-fit: cover;
    object-position: top;
  }

  .book-appointment-form__closeIcon {
    margin-top: 40px;
    margin-bottom: -30px;
  }

  #date {
    height: 43px;
  }
  
  #srvc {
    height: 43px;
  }

  .book-appointment-form__custom-phone-input {
    height: 43px;
    font-size: 13px;
    padding: 1px;
    /* border: 2px solid #ccc; */
    border-radius: 4px;
  }
}


@media (max-width: 500px){
.book-appointment-form__form-group label{
  padding: 0px;
  left: 0px;
}
}