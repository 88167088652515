.why-choose-us {
  padding-right: 3rem;
  margin: auto;
}
.why-choose-us h2 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
}
.why-choose-us h2 span {
  color: #019989;
}
.why-choose-us-bottom {
  display: flex;
  margin-top: 3rem;
}
.why-choose-us-bottom .banner-img {
  border-radius: 36px;
  width: 538px;
}
.grey-text-box {
  align-self: flex-end;
  background: #343436;
  width: 547px;
  padding: 84px 88.41px 90.78px 88px;
  border-radius: 36px;
  margin-bottom: -61px;
  margin-left: -114px;
}
.grey-text-box h3 {
  color: #18fae2;
  font-family: Poppins;
  font-size: 60.43px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0;
}
.grey-text-box p2 {
  color: #fefffe;
  font-family: Poppins;
  font-size: 20.14px;
  font-weight: 400;
  line-height: 36.09px;
  text-align: left;
}
.grey-text-box h6 {
  color: #eeeeee;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  padding: 42px 0px;
  padding-bottom: 64px;
  line-height: 23.5px;
  text-align: left;
}
.grey-text-box .buttons {
  display: flex;
  justify-content: space-between;
}
.grey-text-box button {
  background: #ffffff;
  padding: 14.93px 29.86px 14.93px 29.86px;
  color: #00a292;
  font-family: Poppins;
  font-size: 13.43px;
  font-weight: 500;
  line-height: 14.93px;
  text-align: left;
  border-radius: 22.4px;
}
.grey-text-box button:hover {
  background: #00a292;
  color: #ffffff;
}
.grey-text-box button2 {
  background: #00a292;
  padding: 14.93px 29.86px 14.93px 29.86px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 13.43px;
  font-weight: 500;
  cursor: pointer;
  line-height: 14.93px;
  text-align: left;
  border-radius: 22.4px;
}
.grey-text-box button2:hover {
  background: #ffffff;
  color: #00a292;
}
@media (max-width: 1212px) {
  .why-choose-us-bottom .banner-img {
    width: 500px;
  }
  .grey-text-box {
    width: 509px;
    margin-left: -100px;
    padding: 70px 68.41px 70.78px 70px;
  }
  .grey-text-box h3 {
    font-size: 55px;
  }
  .grey-text-box p2 {
    font-size: 18px;
    line-height: 32.09px;
  }
  .grey-text-box h6 {
    font-size: 14.42px;
  }
}
@media (max-width: 1080px) {
  .why-choose-us-bottom .banner-img {
    width: 400px;
  }
  .grey-text-box {
    width: 409px;
    margin-left: -60px;
    margin-bottom: -40px;
    padding: 50px 68.41px 70.78px 65px;
  }
  .grey-text-box h3 {
    font-size: 50px;
  }
  .grey-text-box p2 {
    font-size: 16px;
    line-height: normal;
  }
  .grey-text-box h6 {
    font-size: 11.82px;
    line-height: normal;
    padding: 30px 0px;
    padding-bottom: 55px;
  }
  .grey-text-box button {
    padding: 12.93px 25.86px 12.93px 25.86px;
    font-size: 11.43px;
    line-height: 12.93px;
  }
  .grey-text-box button2 {
    padding: 12.93px 25.86px 12.93px 25.86px;
    font-size: 11.43px;
    line-height: 12.93px;
  }
}

@media (max-width: 850px) {
  .why-choose-us-bottom .banner-img {
    width: 350px;
  }
  .grey-text-box {
    width: 359px;
    margin-left: -50px;
    padding: 46px 65.41px 52px 65.41px;
  }
  .grey-text-box h3 {
    font-size: 45px;
  }
  .grey-text-box p2 {
    font-size: 14px;
    line-height: normal;
  }
  .grey-text-box h6 {
    font-size: 9.9px;
    line-height: normal;
  }
}
