.all-news-container {
  padding: 20px;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  margin-top: 8%;
}

.AllNews-navigators {
  display: flex;
  list-style: none;
  margin-bottom: 20px;
  max-width: 1250px;
  margin: auto;
}

.AllNews-navitems {
  margin-right: 10px;
  color: #888787;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  
}

.AllNews-navitems:hover {
  color: rgb(0, 81, 255);
  font-weight: 600;
  transition-duration: 300ms;
}
.Aquarian_News_heading {
  font-size: 4rem;
}

.AllNews-heading {
  text-decoration: underline;
  font-size: 30px;
  margin-bottom: 4%;
}

.allnews-search-area-section {
  margin: 20px 0;
}

.allnews-search-box {
  display: flex;
  border: 1px solid black;
  width: 100%;
}

.allnews-search-box input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}

.AllNews-all-news-section {
  margin-top: 40px;
}

.AllNews-news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.AllNews-news-card {
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  transition: transform 0.3s;
  background-color: rgba(225, 226, 227, 1);
}

.AllNews-news-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  border-radius: 10px;
}

.AllNews-news-card-content {
  padding: 10px;
}

.AllNews-news-date {
  font-size: 14px;
  color: #888;
}

.AllNews-news-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.AllNews-news-description {
  font-size: 16px;
  color: #555;
}

.AllNews-read-more {
  color: rgba(0, 152, 137, 1);
  cursor: pointer;
  font-weight: 600;
}

.AllNews-read-more:hover {
  text-decoration: underline;
}

.AllNews-pagination-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.AllNews-pagination-button-prev {
  background-color: #ffffff;
  color: rgb(98, 96, 96);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.AllNews-pagination-button-next {
  background-color: #ffffff;
  color: rgb(98, 96, 96);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.AllNews-pagination-numbers {
  display: flex;
  gap: 10px;
}

.AllNews-pagination-number {
  padding: 10px 15px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.AllNews-pagination-number:hover {
  background-color: #babdbb;
  color: white;
}

/* News Detail Styles */
.newsDetailContainer {
  text-align: center;
}

.newsDetailContainer img {
  height: 40vh; /* 40% of viewport height */
  width: 100%; /* Full width */
  object-fit: cover; /* Maintain aspect ratio */
}

.newsDate {
  color: purple;
  font-weight: bold;
  text-align: start;
  margin-bottom: 28px;
}

.newsTitleDetail {
  font-size: 2em; /* Bigger heading */
  margin: 10px 0;
  text-align: start;
}

.newsContentDetail {
  margin: 20px 5px;
  font-weight: bold; 
  text-align: left;
}

.newsCategoriesList {
  list-style: none;
  padding: 0;
}

.newsCategoryItem {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.backToNewsButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(99, 96, 99);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.AllNews_BTN_CARDS {
  border: 1px solid rgba(99, 99, 99, 1);
  padding: 0.5rem; /* Changed from percentage to rem for better control */
  width: 46%; /* Changed to 100% for responsiveness */
  max-width: 150px; /* Added max-width to limit size */
  text-align: center; /* Center the text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  margin-top: 10%;
  font-size: 14px;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .AllNews-news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .Allnews-heading-section {
    margin-right: 16%;
    margin-left: 10%;
}
}

@media screen and (max-width: 768px) {
  .AllNews-news-grid {
    grid-template-columns: 1fr;
  }
  .Aquarian_News_heading {
    font-size: 2.5rem;
    text-align: center;
  }
  .AllNews-pagination-section {
    flex-direction: row; 
    gap: 10px;
  }

  .AllNews-pagination-button-prev {
    width: 100%; 
    text-align: left;
  }
  .AllNews-pagination-button-next {
    width: 100%; 
    text-align: right;
    position: relative;
  }

  .AllNews-pagination-number {
    width: 100%; 
    text-align: right;
  }

  .all-news-container {
    padding: 20px;
    width: 100%;
    margin-top: 12%;
  }
  .AllNews_BTN_BOX {
    display: flex;
    gap: 5px;
    flex-direction: row;
    
    color: rgba(99, 99, 99, 1);
    flex-wrap: wrap;
  }
  .AllNews_BTN_CARDS {
    border: 1px solid rgba(99, 99, 99, 1);
    padding: 0.5rem;
    width: 46%;
    /* max-width: 150px; */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10%;
  }
}
.news-search-area-section {
  margin: 20px 0;
  text-align: center;

}

.news-search-area-section input {
  width: 100%; /* Adjust width as necessary */
  height: 57px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.news-search-area-section input:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.AllBlogs-SearchBtn {
  border: none;
}

.AllNews_BTN {
  border: 1px solid rgba(99, 99, 99, 1);
  padding: 0.5rem; 
  width: 47%; 
  
  text-align: center; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 
}

.AllNews_BTN:hover {
  background-color: rgba(99, 99, 99, 0.1);
  color: rgba(0, 0, 0, 1);
}

.AllNews_BTN_BOX {
  display: flex;
  gap: 5px;
  flex-direction: row;
  padding: 10px;
  color: rgba(99, 99, 99, 1);
  flex-wrap: wrap;
}

.DESC_HEADINGS {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left; /* Aligns text to the left */
  margin-bottom: 0.5rem; /* Optional: adds space below the heading */
}

.Desc_Para {
  margin-top: 1%;
  text-align: left;
  margin-bottom: 2%;
}
.AllNews-news-card {
  transition: background-color 0.3s, color 0.3s;
  background-color: #ebebeb;
}

.AllNews-news-card:hover {
  background-color: #363535;
  color: black;
  cursor: pointer;
}

.AllNews-news-card:hover .AllNews-news-card-content,
.AllNews-news-card:hover .AllNews-news-date,
.AllNews-news-card:hover .AllNews_news-Heading,
.AllNews-news-card:hover .AllNews-news-description,
.AllNews-news-card:hover .AllNews-read-more,
.AllNews-news-card:hover .AllNews_BTN_CARDS {
  color: rgb(244, 241, 241);
  transition: background-color 0.3s, color 0.3s;
}
.AllNews-news-card:hover .AllNews_BTN_CARDS {
  border: 1px solid white ;
}
.AllNews-news-date {
  font-weight: bold;
}

.AllNews_BTN_CARDS:hover {
  color: white;
  font-weight: bold;
}

.AllNews_news-Heading {
  font-size: 20px;
  font-weight: bold;
}
.Allnews-heading {
  font-size: 30px;
  margin-left: 0px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 40px;
}
.news-button-container{
  margin-top: 10px;
  justify-content: center;
}

