.newsroom-container {
  max-width: 1250px;
  margin: auto;
  margin-top: 7%;
  width: 100%;
}

.newsroom-heading {
  font-size: 4rem;
  margin-left: 7.5%;
}

.recent-news-heading {
  font-size: 30px;
  margin-bottom: 45px;
  font-weight: 800;
  text-decoration: underline;
  margin-left: 3%;
}

.NEWSRIGHT-OUTERCONTAINER {
  border: 3px solid white;
  display: flex;
}

.right-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  width: 48%;
}

.news-image-container {
  position: relative;
}

.small-image {
  width: 100%;
  height: auto;
}

.news-image-overlay {
  position: absolute;
  bottom: 0px;
  border-radius: 10px;
  left: 0;
  right: 0;
  color: white;
  padding: 120px;
  text-align: left;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  pointer-events: none;
}

.recent-news-section {
  padding: 20px;
  margin-right: 20px;
  border-radius: 20px;
  width: 95%;
  margin-left: 3%;
  cursor: pointer;
}
.newsDetailContainer {
  max-width: 1250px;
  margin: auto;
  width: 80%;
}
.recent-news {
  display: flex;
  cursor: pointer;
}

.news-left-image {
  width: 40%;
  position: relative;
  margin-left: 30px;
  cursor: pointer;
}

.left-news-image {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.newsroom_info {
  color: white;
  padding: 10px;
  position: absolute;
  bottom: 0px;
  border-radius: 10px;
  left: 0;
  top: 0;
  right: 0;
  color: white;
  padding: 250px;
  text-align: left;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  pointer-events: none;
}

.news-card-info-heading {
  font-size: 2rem;
  position: absolute;
  top: 100px;
  left: 10px;
  width: calc(100% - 20px);
  padding: 0 10px;
  font-weight: bold;
}
.backToNewsButton {
  margin-bottom: 20px;
}
.news-right-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 1px;
  width: 48%;
  cursor: pointer;
}

.news-image-heading {
  font-size: 1.6rem;
  position: absolute;
  top: 70px;
  left: 10px;
  padding: 0 10px;
  font-weight: bold;
  margin-bottom: 18px;
}

.news-small-image {
  width: 100%;
  height: 230px;
  border-radius: 10px;
}

.news-section-2 {
  width: 100%;
}

.news-navigation {
  display: flex;
  list-style: none;
  font-size: 13px;
  color: #817f7f;
  font-weight: 600;
  max-width: 1250px;
  margin: auto;
  margin-left: 7%;
  margin-top: 10%;

}
.news-navigations-full-screen{
    display: flex;
    list-style: none;
    font-size: 13px;
    color: #817f7f;
    font-weight: 600;
    max-width: 1250px;
    margin: auto;
    margin-top: 10%;
  
  
}

.news-nav-button {
  padding: 5px;
  border: none;
  cursor: pointer;
  font-size: 13px;
}

.news-feature-bar {
  margin-top: 20px;
  gap: 3rem;
}

.news-search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  margin-right: 10%;
  margin-top: 35px;
}

.Allnews-heading-section {
  margin-left: 9%;
}

.search-container {
  position: relative;
}

.news-search-area {
  padding: 10px;
  width: 350px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.news-search-button {
  position: absolute;
  right: 16px;
  top: 11px;
  height: 63%;
  border: 1px solid rgb(14, 202, 14);
  color: rgb(10, 222, 46);
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  padding: 0;
  width: 20%;
}

.newsroomgrid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 10px;
  margin-left: 7%;
  /* margin-right: 8%; */
  width: 83%;
}

.news-grid-item {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgb(225, 226, 227);
  border-radius: 10px;
}

.news-grid-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.grid-item p {
  margin: 10px 0;
}

.Allnews-heading-section {
  font-size: 3rem;
}

@media (max-width: 1024px) {
  .recent-news {
    flex-direction: row;
    justify-content: space-between;
  }

  .right-images {
    width: 48%;
  }

  .news-left-image {
    width: 50%;
    margin-left: 0;
  }

  .news-right-images {
    width: 48%;
  }

  .small-image {
    height: 200px;
  }

  .newsroomgrid-container {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    /* margin: 5px; */
    padding: 18px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .recent-news {
    flex-direction: column;
  }
  .news-image-fullscreen{
    height: 400px;
  }

  .news-image-heading {
  }
  .newsroom-heading {
    font-size: 2.4rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 4%;
  }
  .right-images {
    width: 100%;
  }

  .news-left-image {
    width: 100%;
    margin-left: 0;
  }

  .newsroomgrid-container {
    grid-template-columns: 1fr;
  }

  .small-image {
    height: 150px;
  }
}

.news-read-more-right {
  color: rgba(102, 255, 240, 1);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.news-read-more {
  float: right;
  margin-left: 6%;
  color: rgba(102, 255, 240, 1);
  font-size: 1rem;
  font-weight: 600;
}

.news-card-content {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 2%;
  margin-bottom: 4%;
}
.news-card-date {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  margin: 2%;
  margin-top: 4%;
}

.card-content-news {
  color: rgba(55, 65, 81, 1);
  line-height: 24px;
  margin-bottom: 4%;
  text-align: left;
  margin: 2%;
}

.grid-card-readmore {
  color: rgba(0, 152, 137, 1);
  font-weight: 600;
  text-align: left;
  line-height: 24px;
  cursor: pointer;
}

.news-button-container {
  display: flex;
  gap: 1rem;
  margin-top: 5%;
}

.news-card-btn {
  border: 1px solid rgba(135, 135, 135, 1);
  color: rgba(135, 135, 135, 1);
  padding: 2%;
  width: 60%;
}

@media (max-width: 1024px) {
  .recent-news {
    flex-direction: row;
  }

  .news-left-image {
    width: 50%;
  }

  .news-right-images {
    width: 50%;
    flex-direction: column;
  }

  .info {
    position: absolute;
    top: 120px;
    left: 5px;
    color: white;
    padding: 1px;
    position: space-between;
  }
}

@media (max-width: 768px) {
  .recent-news {
    flex-direction: column;
  }
  .left-news-image {
    width: 100%;
    height: 497px;
    border-radius: 10px;
  }

  .news-left-image {
    width: 100%;
  }
  .info {
    position: absolute;
    top: 200px;
    left: 15px;
    color: white;
    padding: 10px;
  }

  .newsroomgrid-container {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .news-small-image {
    width: 100%;
    height: auto;
  }
}

.Load-morenews-btn {
  text-align: center;
}
.Allnews-button {
  border: 1px solid black;
  margin-top: 3%;
  margin-bottom: 5px;
  padding: 13px;
  border-radius: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
}

.Visit-Blogs {
  border: 1px solid black;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
}

.news-trendingBlogs {
  padding: 20px;
  margin: 20px 0;
  width: 100%;
}

.trending-heading {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 6%;
  font-weight: bold;
  text-decoration: underline;
}

.trending-cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 90%;
  margin-left: 55px;
}

.trending-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
}

.trending-card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 0px;
}

.trending-card-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.trending-card-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.trending-card-title {
  font-size: 1.1rem;
  margin: 10px 0;
}

.trending-card-description {
  flex-grow: 1;
  color: rgba(55, 65, 81, 1);
  line-height: 24px;
  margin-bottom: 4%;
  text-align: left;
  margin: 2%;
}

.trending-card-read-more {
  color: rgb(80, 229, 80);
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .trending-card {
    width: calc(50% - 20px);
  }
  .trending-cards {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0px;
  }
  .recent-news-section {
    padding: 18px;
    border-radius: 20px;
    width: 100%;
    margin: 0 0;
  }
}

@media (max-width: 768px) {
  .trending-card {
    width: 100%;
  }

  .news-trendingBlogs {
    width: 100%;
  }
  .news-trendingBlogs {
    padding: 10px;
    margin: 2px;
    width: 100%;
  }
  .recent-news-section {
    padding: 10px;
    margin-right: 20px;
    border-radius: 20px;
    width: 100%;
  }
  .news-search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10%;
    width: 100%;
  }
  .news-right-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 1px;
    width: 100%;
  }
  .newsroom-container {
    width: 100%;
    margin-top: 15%;
  }

  .recent-news-section {
    width: 100%;
  }
}

.btn-blogs-news {
  text-align: center;
  align-items: center;
  margin-bottom: 40px;
}
.Visitblogs-new-btn {
  border: 0.1px solid green;
  background-color: rgba(0, 152, 137, 1);
  color: white;
  padding: 1%;
  border-radius: 15px;
  font-weight: 600;
}

.news-left-image {
  position: relative;
  overflow: hidden;
}

.left-news-image {
  width: 100%;
  position: relative;
}

.newsroom_info {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.4);
}

.News-Right-content {
  position: absolute;
  top: 245px;
  bottom: 70px;
  left: 20px;
  right: 20px;
}

.News-Right-Paragraphs {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  top: 140px;
}
.News-left-Dates {
  position: absolute;
  font-weight: bold;
  bottom: 12px;
  right: 15px;
}
.News-Right-Dates {
  position: absolute;
  font-weight: bold;
  bottom: 12px;
  right: 15px;
}
.news-search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  /* width: 90%; */
}

.Allnews-heading-section {
  flex: 0 0 30%;
  font-size: 30px;
  text-decoration: underline;
  font-weight: bold;
}

.search-container {
  flex: 1;
  display: flex;
  position: relative;
}

.news-search-area {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
}

.news-search-button {
  position: absolute;
  right: 20px;
  border: none;
  border-radius: 10px;
  color: rgb(14, 202, 14);
  cursor: pointer;
  border: 1px solid rgb(14, 202, 14);
}

@media screen and (max-width: 768px) {
  .Allnews-heading-section {
    font-size: 30px;
  }
  .News-Right-Paragraphs {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 40px;
  }
  .news-image-fullscreen{
    height: 400px;
  }
  .search-container {
    flex: 1 1;
    display: flex;
    position: relative;
    width: 100%;
  }

  .news-image-heading {
    bottom: 90px;
  }
  .news-search-area {
    padding: 8px;
  }
  .News-Right-Dates {
    position: absolute;
    font-weight: bold;
    bottom: 10px;
    right: 10px;
  }

  .news-card-info-heading {
    font-size: 2.4rem;
    position: absolute;
    bottom: 70px;
    left: 10px;
    padding: 0 10px;
    font-weight: bold;
    
  }
}

@media screen and (max-width: 480px) {
  .Allnews-heading-section {
    margin-bottom: 10px;
    width: 100%;
  }

  .search-container {
    width: 90%;
  }
  .news-search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10%;
    width: 100%;
  }
  .news-search-bar {
    align-items: centre;
    width: 100%;
  }
  .news-search-button {
    position: absolute;
    right: 10px;
    top: 8px;
    height: 63%;
    border: 1px solid rgb(14, 202, 14);
    color: rgb(10, 222, 46);
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    padding: 1px;
    width: 20%;
}
  .Allnews-heading-section {
    text-align: center;
    font-size: 30px;
  }
  .news-card-info-heading {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    position: absolute;
    top: 90px;
  }
  .news-image-heading {
    font-size: 1.6rem;
    top: 40px;
    left: 10px;
  }
  .newsroom-container {
    width: 100%;
  }
  .news-search-area {
    margin-right: 0;
  }
}

.news-grid-item {
  background-color: #ebebeb;
  transition: background-color 0.3s, color 0.3s;
}

.news-grid-item:hover {
  background-color: #302f2f;
  color: black;
  cursor: pointer;
}

.news-grid-item:hover .news-card-content,
.news-grid-item:hover .news-card-date,
.news-grid-item:hover .news-card-btn,
.news-grid-item:hover .card-content-news,
.news-grid-item:hover .grid-card-readmore {
  color: rgb(244, 241, 241);
}

.news-card-btn {
  transition: background-color 0.3s;
}

.news-grid-item:hover .news-card-btn {
  border: 1px solid white;
}

.trending-card {
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #ebebeb;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.trending-card:hover {
  background-color: #302f2f;
  color: rgb(244, 241, 241);
}

.trending-card:hover .trending-card-content,
.trending-card:hover .trending-card-date,
.trending-card:hover .trending-card-title,
.trending-card:hover .trending-card-description,
.trending-card:hover .trending-card-read-more {
  color: rgb(244, 241, 241);
}
.trending-card-title {
  font-weight: bold;
}
.trending-card-date {
  font-weight: bold;
  font-size: 13px;
}

.NEWSRIGHT-OUTERCONTAINER {
  color: white;
  display: flex;
  justify-content: space-between;
  border: 3px solid rgb(189, 28, 28);
  height: 200px;
}

.newsheadings{
  text-align: left;
  font-weight: bold;
  font-size: 22px;
margin: 10px 0px;
text-shadow: #817f7f;
}
.news-full-para{
  text-align: left;
  /* font-size: 20px; */

}
.newsContentDetail-full-screen{
  text-align: left;
font-weight: 600;
line-height: 30px;
margin: 10px  0px ;
font-size: 22px;
margin-bottom: 20px;
}
.full-screen-newsroom-image{
  border-radius: 10px;
}
.full-recent-news-heading{
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 15px 0px;
  margin-bottom: 5px;
}
.Recent-news-para{
  font-size: 18px;
  text-align: left;
}