.search-dropdown {
  width: 100%;
  font-size: 13px;
}
.react-select__control {
  min-height: 53px !important;
  border: 1px solid black !important;
  box-shadow: none;
  border-radius: 6px !important;
}

.react-select__control:hover {
  border-color: black;
}

.react-select__dropdown-indicator {
  padding: 8px;
  color: black;
}

.react-select__menu {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-select__menu-list {
  padding: 0;
  color: rgb(0, 0, 0);
}

.react-select__option {
  padding: 10px;
  cursor: pointer;
}

.react-select__option--is-focused {
  background-color: #f0f0f0;
}
.react-select__control--is-focused {
  border-color: black !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-select__option--is-active {
  background-color: #ddd;
}
