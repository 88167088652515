body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* *{
    border: 1px solid red;
  } */

.aquarian-services-container {
  padding: 1rem;
  width: 100%;
  /* border: 5px solid yellow; */
}

.aquarian-services-container-inner-content{
  width:90%;
  margin:auto;
}


.aquarian-services-header {
  font-size: 38px;
  padding-top: 7rem;
  padding-bottom: 3rem;
  font-weight: 400;
  margin:auto;
  /* line-height:40px; */
}


.aquarian-services-header-text {
  font-weight: 700;
}

.aquarian-services-inner-container {
  margin: auto;
  /* width: 90%; */
}

/* ACCORDION CSS */

.accordion-container {
  margin-bottom: 3rem;
}

.accordion-content {
  height: 0; /* Start with height of 0 */
  /* background-color: pink; */
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s ease; /* Smooth transition for height */
}


.accordion-content-inner {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.accordion-row {
  display: flex;
  position:relative;
  align-items: center;
  /* background-color: yellow; */
  border-top: 1px solid #70757a;
  border-bottom: 1px solid #70757a;
}

.accordion-column-container{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
 
}

.accordion-row-inner{
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.closeIcon-accordion-service{
  position: absolute;
  right: 25px;
  top: 18px;
  display: block;
  margin-top: 0.2rem;
  align-self: center;
  margin-left: auto;
  margin-right: -1rem;
  font-size: 1.3rem;
  border-radius: 50%;
  justify-self: flex-end;
  cursor: pointer;
  padding: 0.2rem;
  background-color: #a6bbf1;
}


.accordion-column {
  flex: 1;
  height:280px;
  padding: 3px;
  /* background-color: red; */
  border-left: 1px solid #dadce0;
}

.accordion-column:first-child {
  border-left: none;
  width: 240px;
  padding-left:10px;
}



.accordion-content-image {
  width: 130px;
  border-radius: 10px;
  position: relative;
}

.accordion-content-name {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 17px;
  /* background-color: yellow; */
  max-width:235px;
  margin-left: 9px;
  display: flex;
  flex-direction: column-reverse;
  height:5px;
  line-height:22px;
}

.accordion-content-text {
  width:90%;
  margin:auto;
}

.accordion-image-button-container {
  position: relative;
}

.aquarian-services-button {
  border-radius: 45px;
  border-width: 1px;
  padding: 4px 35px;
  border-color: rgb(18, 5, 5);
  text-align: center;
  transition: all 0.2s;
  margin-top:40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aquarian-services-button-arrow {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  right: 15px;
}

.aquarian-services-button:hover {
  gap: 10px;
  background-color: #8f8f8f !important;
  color: white;
}

.aquarian-services-button:hover .aquarian-services-button-arrow {
  opacity: 1;
  visibility: visible;
}

/* ACCORDION MOBILE */

.accordion-service-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 2rem;
  border-radius: 11px;
  /* border: 1px solid #06d3bf; */
  background: #c6f6f3;
  border-radius: 8px;
  margin-top:-20px;
  position: relative;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease; 
}

.accordion-mobile-active{
  width: 100%;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  /* background-color: #f0f0f0; */
  max-height: max-content;
  opacity: 1;
}

.mobile-closeIcon-accordion-service{
  position: relative;
right: 20px;
display: block;

align-self: center;
margin-left: auto;
margin-right: -1rem;
font-size: 1.3rem;
border-radius: 50%;
justify-self: flex-end;
cursor: pointer;
padding: 0.2rem;
background-color: #a6bbf1;
}

.heading {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}

.imageAccordionServices {
  width: 150px;
  height: 100px;
  /* object-fit: cover; */
  max-width: 300px; /* Adjust as needed */
  margin-bottom: 10px;
  border-radius: 15px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadce0;
  margin: 10px 0;
}

.accordionMobileContent {
  text-align: center;
  padding-bottom: 2rem;
  width: 90%;
}

/* Services page CSS */

.services-header {
  display: flex;
  gap: 8px;
  margin-top: -1rem;
  margin-bottom: 1.2rem;
}

.services-header-text {
  font-size: 24px;
  font-weight: 700;
  color: #70757a;
}

.row-wrapper {
  margin-bottom: 2rem;
}

.row {
  display: grid;
  grid-template-columns:repeat(1,minmax(0, 440px));
  gap: 3rem;
  place-content: center;
 
}

.item-wrapper {
 
  cursor: pointer;
  border-bottom: 1px solid #cbc1c1;
  padding-bottom: 2px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
  transition: all 0.3s ease;
}

.item-header {
  display: flex;
  background-color: #f2efef;
  padding: 3px;
 
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.item-header:hover{
  background-color:#8f8f8f;
  border-radius: 5px;
}

.item-image {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  border-radius: 10px;
  object-fit: cover;
}

.item-header-wrap {
  display: flex;
  align-items: center;
}

.services_toggle {
  margin-right: 15px;
}

.active {
  
  border-bottom: 1px solid #009889;
  /* border: 1px solid #030b0a; */
  border-radius: 5px;
  /* color:black !important; */
  transition: all 0.1s ease;
}

.item-header-wrap h3{
line-height: 16px;
}


.aquarian-services-button-mobile {
  /* background-color: red; */
  border-radius: 45px;
  border-width: 1px;
  padding: 4px 35px;
  border-color: rgb(18, 5, 5);
  /* color: white; */
  text-align: center;
 position: absolute;
 bottom:15px;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aquarian-services-button-arrow-mobile {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  right:15px;
}

.aquarian-services-button-mobile:hover {
  /* padding-right: 32px; */
  background-color: #8f8f8f !important;
  color: white;
  .aquarian-services-button-arrow-mobile{
      opacity: 1;
      visibility: visible;
  }

}




/* SMALLEST SCREEN */

@media screen and (max-width: 500px) {
 

.aquarian-services-header{
  font-size: 35px !important;
}
}



/* TAB Screen */


@media screen and (min-width: 768px) {

  .row {
    grid-template-columns: repeat(
      2,
      minmax(0, 350px)
    ); 
  }

  .accordion-row{
    height:655px;
  }

  .accordion-content-name{
   
  }

}



/* LARGE TAB */

@media screen and (min-width: 1024px) {
  .aquarian-services-header {
    font-size: 44px;
    padding-top: 9rem;
    padding-bottom: 3.5rem;
  }
  .row {
    grid-template-columns: repeat(
      2,
      minmax(0, 450px)
    ); /* Desktop screens: 2 items per row */
    place-content:space-between;

  }

  .accordion-row{
    height: 395px;
  }

  .accordion-row-inner{
    margin-top: 0;
  }



  .accordion-content-image {
    width: 200px;
  }

  .aquarian-services-container {
    padding: 1rem;
    width: calc(100% - 6rem);
    /* border: 5px solid yellow; */
  }
  
  .aquarian-services-container-inner-content{
    width:90%;
    max-width: 1000px;
    margin:auto;
  }

}

/* SMALL PC Screen */

@media screen and (min-width: 1067px) {
 
  .row {
    grid-template-columns: repeat(
      3,
      minmax(0, 300px)
    ); /* Desktop screens: 2 items per row */
    place-content:space-between;

  }



}

@media  screen and (max-width: 1167px){
  .accordion-row{
    height: 425px;
  }
}

@media  screen and (max-width: 1068px){
  .accordion-row{
    height: 425px;
  }
}

@media  screen and (max-width:960px){
  .accordion-content-text{
    width:95%;
  }
}

@media  screen and (max-width:914px){
  .accordion-column{
    height:315px;
  }
}

@media  screen and (max-width:853px){
  .accordion-column:first-child{
    height:320px;
  }

 .accordion-column{
  padding:0;
 }
}

@media  screen and (max-width:830px){

  .accordion-row{
    height: 477px;
    margin-top: -28px;
    overflow:hidden;
 
  }

  .accordion-column:first-child{
    height:330px;
  }

 .accordion-column{
  padding:0;
 }

 .accordion-row-inner{
  margin-top:-35px;
 }
}


@media  screen and (max-width:769px){

  .accordion-row-pas{
    height: 520px;
    margin-top: -28px;
    overflow:hidden;
 padding-bottom:20px;
  }
}
