.prFaq-pages-content-div{
    width: 100% !important;
    padding: 3rem !important;
    padding-top: 3rem !important;
    background-color: #fff;
    min-width: 371px;
    margin-right: 6rem;

}

@media screen and (max-width: 1024px) {
  .prFaq-pages-content-div{
    margin-right:0rem;
  }
}