.footer {
    width: 100%;
    position: relative;
    height: auto;
    --tw-bg-opacity: 1;
    /* background-color: #343434; */
    z-index: 20;
    font: Poppins;
    font-size: 14px;
    color: white;
    background: linear-gradient(0deg, rgba(13, 13, 13, 1) 0%, rgba(52, 52, 52, 1) 20%);
}

@media (min-width: 1024px) {
    .footer {
        padding-right: 6rem;
    }
}


.footer-logo-1 {
    max-width: 350px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: auto;
    margin-left: auto;
}

.footer-inner-links-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.footer-qlinks-box-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-available;
    width: -webkit-fill-available;
    width: -m;
    cursor: pointer;
    padding: 10px 0 10px 0;
    border-width: 1px 0 1px 0;
    margin: 0 20px 0 20px;
    border-color: #636363;
}

.footer-quick-links-header {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
}

.footer-qlinks-box-text {
    color: #FFF;
    text-align: center;
    font-weight: 400;
}

.footer-qlinks-box-arrow {
    margin-left: auto;
}

.footer-outer-quick-links-container {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 50px;
}

.footer-col-2 {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer-about-us {
    margin-top: 50px;
}

.footer-about-us-header {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
}

.footer-about-us-text {
    margin-top: 15px;
    color: #B6B6B6;
    font-family: Poppins;
    font-weight: 400;
}

.footer-about-us-text-2 {
    color: #7B7B7B;
    font-weight: 400;
    margin-top: 25px;
}

.footer-email-field {
    display: flex;
    height: 45px;
    margin-top: 15px;
}

.footer-email-field input {
    width: 80%;
    height: inherit;
    background-color: #474747;
    border-radius: 4px 0 0 4px;
    padding-left: 20px;
    color: white;
}

.footer-email-field button {
    width: 20%;
    height: inherit;
    background-color: #474747;
    border-radius: 0 4px 4px 0;
    color: white;
    border-left-width: 1px;
    border-color: #636363;
}

.footer-email-field img {
    margin-left: auto;
    margin-right: auto;
}

.footer-connect {
    margin-top: 50px;
}

.footer-connect-inner-container {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
}

.footer-connect-inner-container svg {
    width: 18px;
    height: 18px;
}

.footer-connect a {
    border-width: 1.5px;
    border-color: #A3A3A3;
    border-radius: 50px;
    color: #A3A3A3;
    padding: 0.4rem;
    margin-top: 15px;
}

.footer-contact-us {
    margin-top: 4rem;
}

.footer-contact-us-text {
    font-family: Poppins;
    font-weight: 400;
    color: #CECECE;
}

.footer-contact-us-sub-header {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1rem;
}

.footer-toe-text {
    display: flex;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    margin-top: 3rem;
}

.footer-copyright-text {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    color: #797979;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
}

.footer-call-number {
    display: none;
    margin-top: 15px;
    height: 80px;
    background-image: linear-gradient(to right, #5D5D5D, hsl(0 0% 48% / 1), #525252, #4d4c51);
    background-size: 300% 100%;
    box-shadow: rgba(0, 0, 0, 0) 0px 8px 24px;
    border-radius: 4px;
    color: white;
    width: 70%;
    cursor: pointer;
    transition: all 0.3s;
}

.footer-call-number:hover {
    background-position: 100% 0;
}

.footer-call-number-gif {
    display: flex;
    height: inherit;
    height: 80%;
    width: 35%;
    align-items: center;
    justify-content: center;
}

.footer-call-number img {
    height: inherit;
}

.footer-call-number-text {
    height: inherit;
    display: flex;
    align-items: left;
    justify-content: center;
    font-family: Georgia;
    font-size: 18px;
    font-weight: 400;
    flex-direction: column;
    width: 65%;
}

.footer-contact-us-pc {
    color: #9E9E9E;
    display: none;
    margin-top: 3rem;
    padding-right: 1rem;
}

.footer-about-us-text-2-pc {
    display: none;
}

.footer-contact-us-pc-text-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    gap: 1rem;
}

.footer-contact-us-pc-text-2 {
    text-align: end;
}

.footer-contact-us-pc-header {
    font-size: 16px;
}

.footer-logo-2 {
    /* background: linear-gradient(0deg, rgba(13,13,13,1) 11%, rgba(112,112,112,0) 100%); */
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer-copyright-text-pc {
    display: none;
}




@media (min-width: 744px) {
    .footer-col-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        column-gap: 2rem;
        row-gap: 2rem;
    }

    .footer-contact-us-pc {
        display: unset;
        order: 3;
        padding-left: 1rem;
    }

    .footer-col-1 {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
    }



    .footer-logo-1 {
        order: 2;
        margin-top: 3rem;
        margin-right: 0;
        margin-left: 0;
        max-width: unset;
        max-width: 350px;
    }

    .footer-outer-quick-links-container {
        order: 1;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        max-width: unset;
    }

    .footer-quick-links-header {
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;
    }

    .footer-quick-links-header-main {
        font-size: 35px;
        flex-basis: 100%;
    }



    .footer-qlinks-box-link {
        margin-left: 0;
        margin-right: 0;
    }

    .footer-qlinks-box-arrow {
        padding-right: 1rem;
    }

    .footer-col-2 {
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        max-width: unset;
        margin-top: 3rem;
    }

    .footer-contact-us {
        display: none;
    }

    .footer-about-us {
        order: 2;
    }

    .footer-connect {
        order: 1;
        margin-top: 0;
    }

    .footer-call-number {
        display: flex;
        justify-content: center;
        align-items: center;
        order: 3;
        margin-top: 6rem;
        min-width: 300px;
    }

    .footer-email-field {
        width: 80%;
        margin-top: 2.5rem;
        min-width: 300px;
    }

    .footer-about-us-text {
        color: #9E9E9E;
    }

    .footer-about-us-text-2-pc {
        display: inline;
    }

    .footer-about-us-text-2 {
        margin-top: 4rem;
        color: #9E9E9E;
    }

    .footer-about-us-header {
        font-size: 16px;
        font-weight: 400;
        color: #9E9E9E;
    }

    .footer-toe-text {
        padding-left: 3rem;
        margin-left: 0;

        max-width: 1200px;
        font-weight: 500;
        color: #9E9E9E;
        box-sizing: inherit;
        flex-grow: 1;
        /* grid-column: span 2; */
    }

    .footer-copyright-text {
        display: none;
    }

    .footer-copyright-text-pc {
        display: block;
    }

    .footer-logo-2 {
        grid-column: span 2;
        max-width: 1400px;
        padding-left: 2rem;
        padding-right: 2rem;
    }

}

@media (min-width: 1024px) {
    .footer-col-container {
        column-gap: 5rem;
    }

    .footer-quick-links-header-main {
        font-size: 65px;
    }

    .footer-quick-links-header-secondary {
        font-size: 32px;
    }

    .footer-email-field {
        height: 65px;
    }

    .footer-about-us-text-2 {
        margin-top: 6rem;
    }

    .footer-contact-us-pc {
        margin-top: 2rem;
    }

    

}