.all-blogs-container {
  padding: 20px;
  max-width: 1200px;
}

.AllBlogs-navigators {
  display: flex;
  list-style: none;
  margin-bottom: 20px;
}

.AllBlogs-navitems {
  margin-right: 10px;
  color: #333;
  cursor: pointer;
  font-weight: 600;


}

.AllBlogs-navitems:hover{
  color: rgb(0, 81, 255);
  font-weight: 600;
  transition-duration: 300ms;
}

.AllBlogs-heading-blogs {
  margin-bottom: 20px;
}

.AllBlogs-blog-heading {
  font-size: 62px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
}
.AllBlogs-heading{
  text-decoration: underline;
  font-size: 30px;
  margin-bottom: 4%;
  font-weight: 600;
}

.AllBlogs-search-filter-section {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  margin-bottom: 20px;
  width: 95%; 
}
.allnews-search-area-section {
  margin: 20px 0;
}

.allnews-search-box {
  display: flex;
  border: 1px solid black;
  /* border-radius: 20px; */
  width: 100%;

}

.allnews-search-box input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}

.allnews-search-box button {
    padding: 4px;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid black;
    margin: 1%;
    color: white;
    background: black;
    border-radius: 14px;
}

.AllBlogs_total_blogs{
  color: rgba(127, 127, 127, 1);
  margin: 5px;
}

.Allblogs_Filter_btn{
  color: rgba(0, 152, 137, 1);
  /* font-family: Inter; */
font-size: 12px;
font-weight: 400;
border: 1px solid rgba(0, 152, 137, 1);
padding: 3px;
border-radius: 12px;

padding-left: 2%;
padding-right: 2%;

}
.AllBlogs_Filter_section{
  text-align: center;
  line-height: 20px;


}
.AllBlogs-filter-button {
  padding: 7px 15px;
  background-color: #f1f3f5; 
  color: rgb(16, 161, 28);
  border-radius: 40px;
  margin-left: 50%;
  margin-top: 2%;
  border: 1px solid rgb(8, 117, 8);
  cursor: pointer;
  width: 10%; 
}

.AllBlogs-category{
  color: red;
}
.AllBlogs-filter-options {
  margin-top: 10px; 
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 8px; 
}


.AllBlogs-all-blogs-section {
  margin-top: 40px;
}

.AllBlogs-blogs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.AllBlogs-blog-card {
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  transition: transform 0.9s;

}
.AllBlogs-blog-card-image{
border-radius: 10px;
}
.AllBlogs-category{
  list-style: none;
 
}

.AllBlogs-blog-card:hover{
  /* transform: translateY(-5px); */
cursor: pointer;
}


.AllBlogs-blog-card-image {
  width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 10px;
}

.AllBlogs-blog-card-content {
  padding: 10px;
}

.AllBlogs-blog-date {
  font-size: 14px;
  color: #888;
}

.AllBlogs-blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.AllBlogs-blog-description {
  font-size: 16px;
  color: #555;
}



.AllBlogs-blog-categories {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.AllBlogs-category {
  padding: 5px 10px;
  background-color: #fffcfc;
  border-radius: 5px;
}

.AllBlogs-pagination-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.AllBlogs-pagination-button {
  background-color: #ffffff;
  color: rgb(98, 96, 96);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.AllBlogs-pagination-numbers {
  display: flex;
  gap: 10px;
}

.AllBlogs-pagination-number {
  padding: 10px 15px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.AllBlogs-pagination-number:hover {
  background-color: #babdbb;
  color: white;
}

.AllBlogs-search-bar {
  position: relative;
  width: 100%;
   
  display: flex;
  align-items: center;
}

.AllBlogs-search-input {
  flex: 1; 
  padding: 17px;
  width: 100%;
  padding-right: 90px; 
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid grey;
  outline: none;
}

.AllBlogs-search-button {
  position: absolute;
  right: 10px; 
  padding: 10px 20px;
  background-color: #3c3e3c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  .AllBlogs-blogs-grid{
    grid-template-columns: repeat(2, 1fr);
  }
  
}

@media screen and (max-width: 768px) {
  .AllBlogs-blogs-grid {
    grid-template-columns: 1fr; /* Display 1 item per row */
  }

  .AllBlogs-blog-heading {
    font-size: 44px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  .AllBlogs-search-filter-section {
    flex-direction: column; /* Stack search bar and filter button */
    gap: 10px;
  }

  .AllBlogs-search-bar {
    width: 100%; /* Make search bar take full width */
  }

  .AllBlogs-filter-button {
    width: 100%; /* Make filter button take full width */
  }

  .AllBlogs-pagination-section {
    flex-direction: row; /* Stack pagination controls */
    gap: 10px;
  }

  .AllBlogs-pagination-button,
  .AllBlogs-pagination-number {
    width: 100%; /* Make pagination buttons take full width */
    text-align: center;
  }
  
}

.AllBlogs-SearchBtn{
  color: white;
}
.AllBlogs-blog-card {
  position: relative;
  transition: all 0.3s ease; /* Smooth transition for card */
}

.AllBlogs-blog-card img {
  height: 200px; /* Default height */
  width: 100%;
  object-fit: cover; /* Keep aspect ratio */
  transition: height 0.3s ease; /* Smooth transition for height */
}

.AllBlogs-blog-detail img {
  height: 350px; /* Height when in detail view */
}

.AllBlogs-blog-title {
  font-size: 1.5em; /* Adjust title size */
  margin: 10px 0;
}

.AllBlogs-blog-description {
  margin: 10px 0;
}

.AllBlogs-read-more {
  text-decoration: none;  cursor: pointer;
  font-weight: 600; color: #007bff;
  }

.AllBlogs-read-more:hover {
  color: #0056b3; /* Darker color on hover */
}

/* Additional styles for the blog detail view */
.AllBlogs-blog-detail {
  margin: 20px 0; /* Spacing for detail view */
}

.AllBlogs-blog-detail h3 {
  margin-bottom: 15px;
}

.AllBlogs-blog-detail p {
  margin-bottom: 10px;
}

.AllBlogs-blog-categories {
  list-style-type: none; /* Remove default list style */
  padding: 0; /* Remove default padding */
}

.AllBlogs-category {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #ffffff; /* Light background for categories */
  border-radius: 5px;
}
/* */
.blogDetailContainer {
  text-align: center;
}

.blogDetailContainer img {
  height: 60vh; /* 60% of viewport height */
  width: 100%; /* Full width */
  object-fit: cover; /* Maintain aspect ratio */
}

.blogDate {
  color: purple;
  font-weight: bold;
  text-align: start;
  margin:4px;
}

.blogTitleDetail {
  font-size: 2em; /* Bigger heading */
  margin: 40px 0;
  text-align: start;
  font-weight: bold;
}

.blogDescriptionDetail {
  font-style: italic;
  margin-top: 20px;
  font-weight: 400; /* Minor bold */
  /* margin: 10px 0; */
  text-align: start;
}

.blogCategoriesList {
  margin:30px;
  margin-top: 60px !important;
  list-style: none;
  padding: 0;
}

.blogCategoryItem {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.backToBlogsButton {
  margin-top: 20px;
  margin-bottom:140px;
  padding: 10px 20px;
  background-color: rgb(99, 96, 99);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.BLOGS_SINGLE_HEADING
{
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left; /* Aligns text to the left */
  margin-bottom: 0.5rem;
  margin-top:50px;
}

.BLOGS_DESC
{
  margin-top: 1%;
  text-align: left;
  margin-bottom: 2%;
}
.AllBlogs-grid-outerBox{
  width: 100%;
}


@media screen and (max-width: 1024px) {
  
}


@media screen and (min-width: 1024px) {
  .AllBlogs-blogs-grid {
    grid-template-columns: repeat(3, 1fr); /* Still 3 items per row until 1024px */
  }
}



.AllBlogs-blog-card {
  transition: background-color 0.3s, color 0.3s;
}

.AllBlogs-blog-card:hover {
  background-color: rgb(73, 72, 72);
  color: black;
}

.AllBlogs-blog-card:hover .AllBlogs-blog-card-content,
.AllBlogs-blog-card:hover .AllBlogs-blog-date,
.AllBlogs-blog-card:hover h3,
.AllBlogs-blog-card:hover p {
  color: rgb(244, 241, 241);
}



.AllBlogs-all-blogs-section {
  margin-top: 40px;
}

.AllBlogs-heading {
  text-decoration: underline;
  font-size: 30px;
  margin-bottom: 4%;
}

.AllBlogs-blogs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.AllBlogs-blog-card {
  border: 1px solid #ddd;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s;
}

.AllBlogs-blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  border-radius: 10px;
}

.AllBlogs-blog-card-content {
  padding: 10px;
}

.AllBlogs-blog-date {
  font-size: 14px;
  color: #1b1b1b;
}

.AllBlogs-blog-title {
  font-size: 20px;
  font-weight: bold;
}

.AllBlogs-blog-description {
  font-size: 16px;
  color: #555;
}

.AllBlogs-blog-categories {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.AllBlogs-category {
  padding: 5px 10px;
  border-radius: 5px;
}

.AllBlogs-blog-card:hover {
  background-color: rgb(73, 72, 72);
  color: black;
  cursor: pointer;
}

.AllBlogs-blog-card:hover .AllBlogs-blog-card-content,
.AllBlogs-blog-card:hover .AllBlogs-blog-date,
.AllBlogs-blog-card:hover .AllBlogs-blog-title,
.AllBlogs-blog-card:hover .AllBlogs-blog-description {
  color: rgb(244, 241, 241);
  transition: background-color 0.3s, color 0.3s;
}
/* Base styles for the grid */
.AllBlogs-blogs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 columns */
  gap: 20px;
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
  .AllBlogs-blogs-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for screens <= 1024px */
  }
}

@media screen and (max-width: 768px) {
  .AllBlogs-blogs-grid {
    grid-template-columns: 1fr; /* 1 column for screens <= 768px */
  }
}
