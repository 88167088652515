.migrate-box-container {
    min-height: 1200px;
    width: 100%;
    min-width: 371px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-top: 1rem;
}

.migrate-box-header {
    padding-top: 10px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700;
    color: #067369;
}

.migrate-box-summary {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    max-width: 410px;
}

.migrate-box-inner-box {
    display: grid;
    padding-top: 20px;
    row-gap: 20px;
}

.migrate-box-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.migrate-box-card-text {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-top: 15px;
    max-width: 164px;
}

.migrate-box-card-header {
    color: #009889;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    padding-top: 10px
}

.migrate-box-arrows-container {
    display: none;
}

.migrate-box-arrows-container-2 {
    display: none;
}

@media (744px < width < 1400px) {
    .migrate-box-inner-box {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "inquiry counselling"
            "documentation eligibility"
            "processing processing";
        gap: 180px;
        grid-template-rows: auto auto auto;
        row-gap: 75px;
    }

    .migrate-box-container {
        min-height: 920px;
        height: 970px;
    }

    .migrate-box-arrows-container {
        position: relative;
        top: -230px;
        right: 190px;
        display: block;
    }

    .migrate-box-arrow-1 {
        position: relative;
        left: 70px;
    }

    .migrate-box-arrow-2 {
        position: relative;
        bottom: 500px;
        left: 190px
    }

    .migrate-box-arrow-3 {
        position: relative;
        bottom: 280px;
        left: 200px;
    }

    .migrate-box-arrow-4 {
        position: relative;
        bottom: 392px;
        left: 452px;
    }

    .migrate-box-card-container:nth-child(1) {
        grid-area: inquiry;
    }

    .migrate-box-card-container:nth-child(2) {
        grid-area: counselling;
    }

    .migrate-box-card-container:nth-child(3) {
        grid-area: eligibility;
    }

    .migrate-box-card-container:nth-child(4) {
        grid-area: documentation;
    }

    .migrate-box-card-container:nth-child(5) {
        grid-area: processing;
    }
}

@media (min-width: 1400px) {
    .migrate-box-inner-box {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            "inquiry inquiry eligibility eligibility processing processing"
            ". counselling counselling documentation documentation .";

        gap: 160px;
        row-gap: 90px;
        padding-top: 40px;

    }

    .migrate-box-container {
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;
        min-height: 600px;
        height: 690px;
        margin-top: 8rem;
    }

    .migrate-box-summary {
        max-width: 800px;
    }

    .migrate-box-arrows-container-2 {
        position: relative;
        top: -350px;
        display: block;
    }

    .migrate-box-arrow-5 {
        position: relative;
        right: 75px;
        top: 70px
    }

    .migrate-box-arrow-6 {
        position: relative;
        right: 250px;
        bottom: 20px;
    }

    .migrate-box-arrow-7 {
        position: relative;
        bottom: 110px;
        left: 280px;
    }

    .migrate-box-arrow-8 {
        position: relative;
        bottom: 190px;
        left: 80px;
    }

    .migrate-box-card-container:nth-child(1) {
        grid-area: inquiry;
    }

    .migrate-box-card-container:nth-child(2) {
        grid-area: counselling;
    }

    .migrate-box-card-container:nth-child(3) {
        grid-area: eligibility;
    }

    .migrate-box-card-container:nth-child(4) {
        grid-area: documentation;
    }

    .migrate-box-card-container:nth-child(5) {
        grid-area: processing;
    }

}