.elig-card-container {
    position: relative;

    margin-left: auto;
    margin-right: auto;
    height: 244px;
    overflow: hidden;
}

.elig-card-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    /* relative to container*/
    background-color: black;
    opacity: 0.35;
}

.elig-card-background-img{
    object-fit: cover;
    width: 100%;
    /* Ensures the image fills the container */
    height: 100%;
    /* Maintain aspect ratio */
    display: block;
    /* Remove bottom space/gap */
}

.elig-card-header {
    position: absolute;
    top: 0;
    min-width: 380px;
    color: #FFF;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    padding-top: 30px;
    padding-left: 15px;
}

.elig-card-text {
    position: absolute;
    top: 0;
    padding-top: 80px;
    color: #FFF;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    width: 200px;
}

.elig-card-container button {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    margin-left: 15px;
    margin-top: 170px;
    background-color: #FFF;
    width: 156px;
    height: 40px;
    color: #020202;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    transition: width 0.3s ease-in-out;
}

.elig-card-button-text{
    position: absolute;
    left:0;
    padding: 30px;
}

.elig-card-button-arrow {
    position:absolute;
    left:0;
    margin-left: 128px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 24px;
    height: 24px;
}

.elig-card-container button:hover{
    width: 186px;
    background: #D7D7D7;
    .elig-card-button-arrow{
        opacity: 1;
    }
}



@media (min-width: 744px) {
    .elig-card-mask {
        width: 580px;
        transform: skewX(-35deg);
        /* Slants the div to the left */
        transform-origin: top right;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 14%, rgba(255, 255, 255, 1) 100%);
    }
    .elig-card-header{
        padding-top: 40px;
        padding-left: 30px;
    }
    .elig-card-text{
        padding-top: 90px;
        padding-left: 30px;
        width: 400px;
    }
    .elig-card-container button{
        margin-left: 30px;
    }
    .elig-card-container{
        margin-top:7rem;
    }
}

@media (min-width: 1400px) {
    .elig-card-container {
        max-width: 880px;
        margin-top: 7rem;
    }

    .elig-card-header{
        font-size: 28px;
    }

}

@media (max-width: 420px){
    .elig-card-header{
        font-size: 20px;
    }
}