.pages-content-wrapper {
  display: flex;
  margin-top: 6.5rem;
}

.pages-content-div {
  /* width: 74%; */
  /* padding: 3rem; */
  /* padding-top: 3rem; */
  background-color: #fff;
  min-width: 371px;
  margin-right: 6rem;
}

/* Needs to be removed/changed in all pages */
.AboutUsHeader {
  height: 6.5rem;
}

.pages-content-text-wrapper {
  padding-right: 7rem;
  padding-left: 5rem;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 72px;
  max-width: 1600px;
}

.pages-content-text-wrapper-direct-pathway {
  padding-right: 5rem;
  padding-left: 5rem;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 72px;
  max-width: 1600px;
}

.pages-header {
  font-family: "GFS Didot";
  font-size: 50px;
  font-weight: 400;
  margin-top: 1.8rem;
  margin-bottom: 3.5rem;
  line-height: 47px;
}

.pages-header-2 {
  color: #5d5858ae;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  padding-top: 70px;
  line-height:37px;
}

.pages-header-3 {
  color: #3D3D3D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-top: 48px;
}

.pages-header-4 {
  color: #000;
  font-weight: 600;
}

.pages-paragraph {
  padding-top: 35px;
}

.pages-content-div bold {
  font-weight: 600;
}

.pages-content-div italic {
  font-style: italic;
}

.pages-content-div ul {
  margin-left: 2rem;
}

.pages-content-div ul {
  list-style-type: disc;
}

/* what is this */
.expandedTable {
  display: none;
}


.pages-current-page-link {
  color: #747474;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2.5rem;
}

.pages-list {
  padding-top: 32px
}

.pages-link {
  color: #034AFF;
  text-decoration: underline;
}

.pages-bold {
  color: #3D3D3D;
  font-weight: 600;
}

.ordered-list{
  list-style: auto;
  margin: revert;
  padding: revert;
}

.pages-table{
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  max-width: 700px;
}

.pages-table th, 
.pages-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.pages-table tr {
  background-color: #fffefe;
  transition: all 0.2s;
}

.pages-table tr:hover {
  background-color: #f1f1f1;
}
.pages-table th {
  background-color: #f2f2f2;
}


@media (max-width:1200px) {
  .pages-content-text-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .pages-content-text-wrapper-direct-pathway {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}


@media (max-width: 1024px) {
  .pages-content-div {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .pages-content-wrapper {
    margin-top: 4rem;
  }
  .pages-table{
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  .pages-content-text-wrapper {
    font-size: 15px;
  }

  .pages-header {
    font-size: 72px;
    line-height: 72px;
  }

 
}