
:root {
  --sidebar-width: 6rem; /* Define variable globally for both children */
}

.sidebar {
  width: var(--sidebar-width);
  position: fixed;
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgb(36 37 37 / var(--tw-bg-opacity));
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 99;
  visibility: hidden;
  font-size: 12px;
}

.sidebar-img {
  filter: invert(100%) brightness(200%);
  transition: filter 0.3s ease-in-out;
}

.parent:hover .sidebar-img {
  filter: none;
}

@media (min-width: 1024px) {
  .sidebar {
    visibility: visible;
  }
}