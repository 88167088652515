.blogs-container-main {
  padding:20px 40px;
  width: calc(100vw - 6.2rem);
  display: flex;
  margin-top:7%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-universities-container{
  max-width: 1200px;
}

@media (max-width: 1024px){
  .blogs-container-main {
    margin:auto;
    margin-top:8%;
    width:100%;
  }
}

.top-navigators {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.top-navigator-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: left;
  gap: 15px;
}

.top-navigator-item {
  cursor: pointer;
  color: #3c3e3c;

  font-weight: bold;
  transition: color 0.3s;
}

.top-navigator-item:hover {
  color: #007bff; /* Change color on hover */
}

.top-page-heading {
  text-align: left;
  text-decoration: underline;
  margin: 20px 0;
  font-size: 2rem;
}

.top-image-section {
  width: 100%;
  overflow: hidden; /* Ensures image does not overflow */
}

.top-responsive-image {
  width: 100%;
  height: auto;
}

.top-content-section {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}
.top-content-heading{
  font-size: larger;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.mainpage-btn{
  background-color: lightgray;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5%;
}
.mainpage-btn:hover{
background-color: #646764;
color: white;
transition-duration: 100ms;

}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-page-heading {
    font-size: 1.5rem; /* Smaller heading on mobile */
  }

  .top-navigator-list {
    flex-direction: row; /* Stack navigators vertically on mobile */
    align-items: center;
    margin-top: 4%;
  }

  .top-navigator-item {
    margin-bottom: 10px;
    
  }
}
