


.Blogs-navigators{
  margin-top: 12%;
  display: flex;
  list-style: none;
  gap: 0.4rem;
  margin-left: 3%;
  max-width: 1250px;
  margin: auto;
  font-size: 13px;
}
.Blogs-navitems{
  color: rgb(125, 123, 123);
  font-weight: 600;
} 
.Blogs-heading-blogs{
  font-size: 4rem;
margin: auto;
max-width: 1250px;}

.BLOGS_OUTER_CONTAINER{
  margin-left: 8%;
  margin-right: 10%;
  margin-top: 8%;
}
.Blogs-section {
  margin-top: 1%;
  margin-bottom: 4%;
}

.Blogs-container {
  max-width: 1250px;
margin: auto
;}

.Blogs-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.Blogs-recent-blog-heading {
  font-size: 24px;
  font-weight: 800;
  text-decoration: underline;
margin-top: 45px;
margin-bottom: 20px;
}

.Blogs-searcher input {
  padding: 14px;
  width: 500px;
  border: 0.5px solid grey;
  border-radius: 30px;
}

.Blogs-content-box {
  display: flex;
  justify-content: space-between;
}

.Blogs-date-left{
  color: rgb(105,65,198,1);
  font-weight: 600;
  margin-bottom: 2%;
  /* margin-top: 1%; */
}
.Blogs-top-10{
  font-size: 1.6rem;
  margin-top: 1%;
  margin-bottom: 2%;
}
.Blogs-left-content{
  color: rgb(39, 38, 38);
  margin-bottom: 2%;
/* font-weight: bold; */
}
.Blogs-read-more{
  color: rgb(92, 92, 220);
  font-weight: 500;
  margin-bottom: 3%;
  font-size: 13px;
  font-weight: 600;

}
.Blogs-content-below-image {
  padding: 10px; 
  max-width: 100%; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  box-sizing: border-box; 
}

.Blogs-purple{
  color: rgb(214, 88, 214);
  border: 0px solid rgb(190, 8, 190);
  border-radius: 10px;
  background-color: rgb(222, 184, 222);
  padding-left: 2%;
  padding-right: 2%;
}
.Blogs-green{
  color: rgb(62, 212, 62);
  border: 0px solid rgb(8, 190, 44);
  border-radius: 10px;
  background-color: rgb(209, 242, 209);
  padding-left: 2%;
  padding-right: 2%;
}
.Blogs-pink{
  color: rgb(241, 133, 151);
  border: 0px solid rgb(237, 169, 232);
  border-radius: 10px;
  background-color: rgb(247, 221, 225);
  padding-left: 2%;
  padding-right: 2%;
}
.Blogs-blue{
  color: rgb(17, 118, 219);
  border: 0px solid rgb(81, 157, 227);
  border-radius: 10px;
  background-color: rgb(219, 238, 244);
  padding-left: 2%;
  padding-right: 2%;
}
.Blogs-left-below-list{
  display: flex;
  text-decoration: none;
  list-style: none;
  gap: 2rem;
}
.Blogs-left-side, .right-side {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.Blogs-left-side{
  cursor: pointer;
  height: 428px;
  padding: 6px;
  width: 50%;
  background: #edebed;
  /* border: 1px solid black; */
}
.Blogs-image-box img, .image-box-right img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 10px ;
}


.Blogs-content-below-image{
  padding-left: 10px;
}

@media (max-width: 768px) {
  .Blogs-content-box {
    flex-direction: column;
  }

  .Blogs-left-side, .Blogs-right-side {
    width: 100%;
    margin-bottom: 20px;
  }
}
.Blogs-right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 49%; 
  cursor: pointer;
}

.Blogs-right-content-block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px; 
  
  padding: 7px;
  
  background: #edebed;
}

.Blogs-image-box-top img {
  width: 300px; 
  height: 190px;
  object-fit: cover; 
  margin-right: 15px; 
  border-radius: 10px;
}

.Blogs-image-box-bottom img {
  width: 300px; 
  height: 190px;
  object-fit: cover; 
  margin-right: 15px; 
  border-radius: 10px;

}
.Blogs-content-right-side h1 {
  font-size: 18px;
  margin: 5px 0;
}

.Blogs-content-right-side p {
  width: 100%;
}
.Blogs-Trending-list-news{
  display: flex;
  list-style-type: none;
  gap: 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.Blogs-news-blue{
color: blue;
}
.Blogs-news-green{
color: green;
}
.Blogs-news-red{
color: red;
}


.Blogs-date-right{
  color: rgb(105,65,198,1);
  font-weight: 600;
  margin-bottom: 1%;
  font-size: 13px;
  /* padding-left: 10px; */
}

.Blogs-top-10-right {
  transition: color 0.3s ease, text-shadow 0.3s ease;
  font-weight: bold;
}

.Blogs-top-10-right:hover {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: rgb(56, 56, 56);
}



.Blogs-right-content{
  color: rgb(39, 39, 39);

  
}

.Blogs-read-more-right{
  color: rgb(92, 92, 220);
  font-weight: 700;
  padding-left: 6%;
  font-size: 13px;
  cursor: pointer;
}
.Blogs-right-below-list{
  display: flex;
  list-style: none;
  gap: 1rem;
  padding-left: 25px;
}
.Blogs-blue-r{
  color: blue;
  border: 0px solid rgb(210, 210, 237);
  border-radius: 10px;
  background-color: rgb(225, 225, 236);
  padding-left: 2%;
  padding-right: 2%;


}
.Blogs-green-r{
  color: green;
  border: 0px solid rgb(217, 241, 217);
  background-color: rgb(227, 236, 227);
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 10px;


}
.Blogs-pink-r{
  color: rgb(242, 126, 145);
  background-color: rgb(252, 228, 232);

  border: 0px solid rgb(230, 221, 223);
  padding-left: 2%;
  border-radius: 10px;
  padding-right: 2%;

}
.Blogs-red-r{
  color: red;
  background-color: rgb(232, 219, 219);
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 10px;
  border: 0px solid red;
}

.Blogs-new-section {
  margin-top: 50px;
  }

.Blogs-new-container {
  display: flex;
  flex-direction: column;
  /* gap: 40px;  */
  max-width: 1250px;
  margin: auto;
}

.Blogs-new-heading {
  width: 100%;
  margin-bottom: 20px;
}

.Blogs-new-section-heading {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  text-decoration: underline;
  margin-top: 20px;

  /* margin-bottom: -20px; */
}

.Blogs-image-content-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2%;
}

.Blogs-new-left {
  width: 49%;
}

.Blogs-new-image-box img {
  width: 600px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}



.Blogs-new-right-heading {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  transition: text-shadow 0.3s ease;
cursor: pointer;
margin-top: 8px;
}

.Blogs-new-right-heading:hover{
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

}
.Blogs-new-right-content {
  font-size: 16px;
  margin-bottom: 10px;
  /* font-weight: bold; */
}

.Blogs-new-right :hover{}
.Blogs-new-read-more {
color: blue;  
/* font-weight: 600; */
  cursor: pointer;
}
.Blogs-trending-item{
  background-color: white;
  border: 1px solid white;
  padding: 1%;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  margin-top: 25px;
}
.Blogs-trending-item:hover{
text-shadow: 1px 1px 1px;
}

p.Blogs-new-read-more {
  color: rgba(3, 74, 255, 1);
}
@media (max-width: 768px) {
  .Blogs-image-content-block {
    flex-direction: column;
    gap: 20px;
  }
.Blogs-new-right {
  width: 100%;
  padding: 10px;
  /* z-index: 123; */
}


  .Blogs-new-left, .new-right {
    width: 100%;
  }
}

.Blogs-Trending-list{
  display: flex;
  list-style: none;
  gap: 7rem;
  /* margin: 2%; */

}

.Blogs-trending-blue{
color: rgb(111, 33, 220);
font-weight: 400;
}
.Blogs-trending-red{
color: rgb(220, 70, 70);
font-weight: 400;

}

.Blogs-popular-section {
  margin-top: 50px;
  /* padding: 20px; */
  font-size: 24px;
  font-weight: 800;
}

.Blogs-popular-container {
  max-width: 1250px;
  margin: 0 auto;
}

.Blogs-popular-heading {
  font-size: 24px;
  text-align: left;
  text-decoration: underline;
  margin-bottom: 35px;
  margin-top: 75px;
}

.Blogs-grid-container {
  display: flex;
  flex-direction: column;
  gap: 30px; 
}

.Blogs-grid-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; 
}
.Blogs-top-10-right{
  font-size: 1.5rem;
}
.Blogs-top-10{
  font-weight: bold;
}
.Blogs-card {
  background-color: #edebeb;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 32%; 
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
}
.Blogs-card:hover{
  /* transform: translateY(-5px); */
  background-color: rgb(136, 133, 133);
  cursor: pointer;
  /* transition-duration: 3000ms; */
  color: white;

}

.Blogs-card-image img {
  width: 85%; 
  height: 250px; 
  object-fit: cover; 
  border-radius: 8px 8px 0 0; 
  padding: 10px;
  border-radius: 20px !important;
}


.Blogs-card-content {
  padding: 15px;
  padding-top: 3px;
}

.Blogs-card-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.Blogs-card-content p {
  /* font-size: 14px; */
  color: #666;
}
.Blogs-card-content p:hover{
  /* font-size: 16px; */
  color: #fcfafa;
}

.Blogs-load-more-button {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.BLOGS_DECS{ font-size: 15px!important;}
.Blogs-load-more-button button {
  padding: 10px 20px;
  background-color:  rgba(0, 152, 137, 1);
  ;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.Blogs-searcher {
  position: relative;
  width: 550px;
  max-width: 1200px; /* Adjust as necessary */
  display: flex;
  align-items: center;
}





@media (max-width: 768px) {
  .Blogs-grid-row {
    flex-direction: column;
    gap: 20px;
  }

  .Blogs-card {
    width: 100%; 
  }
  .Blogs-popular-section {
    margin-top: 0px;
    padding: 20px;
  }
}

.Blogs-trending-news-section {
  padding: 40px 0;
  background-color: #f5f5f5;
  max-width: 1250px;
  margin: auto;
}

.Blogs-trending-container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 20px;
}

.Blogs-trending-heading {
  font-size: 28px;
  margin-bottom: 30px;
  text-decoration: underline;
  max-width: 1250px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 40px;
}

.Blogs-trending-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
}

.Blogs-trending-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.Blogs-trending-card:hover {
  /* transform: translateY(-5px); */
  color: black;
}

.Blogs-card-image img {
  width: 100%;
  height: 240px; 
  object-fit: cover; 
  border-radius: 8px;
}

.Blogs-card-content {
  padding: 20px;
}

.Blogs-card-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.Blogs-card-content p {
  /* font-size: 16px; */
  color: #555;
}

.Blogs-visit-newsroom-button {
  text-align: center;
  margin-top: 30px;
}

.Blogs-newsroom-btn {
  padding: 10px 30px;
  background-color: #090a0a;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}



.Trending-list-news{
  display: flex;
  list-style: none;
  gap: 2rem;
  margin: 2%;

}
.news-red{
  color: rgb(240, 2, 2);
  font-weight: 300;
  font-size: small;
}
.news-green{
  color: rgb(14, 153, 203);
  font-weight: 300;
  font-size: small;

}
.news-blue
{
  color: rgb(0, 98, 255);
  font-weight: 300;
  font-size: small;

}
@media (min-width: 769px) and (max-width: 1024px) {
  .grid-row {
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }

  .card {
    width: 48%; 
    margin-bottom: 20px; 
  }
}

@media (max-width: 768px) {
  .grid-row {
    flex-direction: column; 
  }
  .Blogs-trending-grid {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    flex-direction: column;
}
  .card {
    width: 100%; 
    margin-bottom: 20px; 
  }
}




.Blogs-search-input {
  flex: 1;
  padding: 12px;
  padding-right: 90px; 
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid grey;
  outline: none;
}

.Blogs-filter-button {
  position: absolute;
  right: 10px; 
  padding: 3px 20px;
  color: rgba(0, 152, 137, 1);
  border: 1px solid rgba(0, 152, 137, 1);
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
}

@media (max-width: 768px) {
  .Blogs-frame {
    flex-direction: column; 
    align-items: flex-start; 
  }
  .Blogs-top-10{
    font-size: 17px;
  }
.Blogs-date-left{
  font-size: 13px;
}
.Blogs-left-content{
  font-size: 15px;
}
.Blogs-new-right{
  height: 300px!important;
}
.Blogs-content-right-side{
  width: 320px!important;
  /* margin-top: 10px; */
  padding: 10px;

}
.Blogs-trending-heading{
  margin-left: 15px;
}
.Blogs-top-10-right{
  font-size: 17px;
}
.Blogs-right-content{
  font-size: 15px;
}
.Blogs-new-right-heading{
  font-size: 17px;
}
.Blogs-new-right-content{
  font-size: 15px;
}
.Blogs-card-content h3{
  font-size: 17px;
}
.Blogs-trending-date{
  font-size: 13px!important;
}
.NEWSROOM_CARDS_CONTENT{
  font-size: 15px!important;
}
  .BLOGS_OUTER_CONTAINER{
    margin-left: 1%;
    margin-right: 1px;
    margin-top: 18%;
  }
  .Blogs-new-section {
    margin-top: 0px;
    padding: 15px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
}
.Blogs-right-content-block {
  display: flex;
  /* align-items: -start; */
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}
.Blogs-Trending-list {
  display: flex;
  list-style: none;
  gap: 1rem;
  margin: 2%;
}
.Blogs-card-image img {
  width: 100%;
  /* height: 240px; */
  object-fit: cover;
}
  .Blogs-searcher {
    width: 100%; 
    margin-top: 10px; 
  }
  .Blogs-card-content {
    padding: 9px;
}
.Blogs-container {
  max-width: 1200px;
  margin: 5%;
}
.Blogs-blog-heading{
  font-size: 3rem;
}
.Blogs-frame{
display: flex;
justify-content: space-between;
align-items: center;
/* margin-bottom: 40px; */
gap: 0rem;
}
.Blogs-content-right-side{
  width: 100%;
}
.Blogs-right-content-block
{
  display: flex;
}
.Blogs-right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%; 
}
.Blogs-image-box-top img {
  width: 520px;
  height: 190px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 10px;
}
.Blogs-new-right {
  width: 100% !important;
  /* text-align: left; */
  align-items: left;
  margin-left: 5px;
}

.Blogs-image-box-bottom img {
  width: 700px;
  height: 190px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 10px;
}
.Blogs-new-image-box img {
  width: 700px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}
.Blogs-image-box-top img {
  width: 720px;
  height: 190px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 10px;
}

}
p.Blogs-new-read-more {
  color: rgba(3, 74, 255, 1);
}

.blogDescriptionHeading
{
  text-align: left;
  font-weight: 600;
  margin: 2%;
}
.blogDescriptionDescription
{
  text-align: left;
  margin: 2%;

}

.Blogs-new-right {
  width: 49%; 
  margin-left: 10px; 
  cursor: pointer; 
  height: 250px;
}


.Blogs-content-right-side{
  height: 190px;
  width: 50%;
}

.Blogs-top-10-right{
  font-size: 2rem;
}
.Blogs-content-right-side h1 {
  font-size: 20px;
  margin: 5px 0;
}
.Blogs-content-right-side{
  padding-left: 10px;
}
.Blogs-trending-item {
  background-color: white;
  border: 1px solid white;
  padding: 1%;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  margin-top: 25px;
  font-size: 13px;
  font-weight: 500;
}
.Blogs-load-more-button button {
  padding: 10px 20px;
  background-color: rgba(0, 152, 137, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}
.Blogs-card {
  transition: background-color 0.3s, color 0.3s; 
}

.Blogs-card:hover {
  background-color: rgb(73, 72, 72); 
  color: black; 
}


.Blogs-card:hover .Blogs-card-content,
.Blogs-card:hover .Blogs-trending-date,
.Blogs-card:hover .BLOGS_DECS,
.Blogs-card:hover .Blogs-trending-item {
  color: rgb(244, 241, 241); 
}

.Blogs-card {
  transition: background-color 0.3s, color 0.3s;
}

.Blogs-card:hover {
  background-color: rgb(73, 72, 72);
  color: black; 
}

.Blogs-card:hover .Blogs-card-content,
.Blogs-card:hover .Blogs-trending-date,
.Blogs-card:hover h3,
.Blogs-card:hover p,
.Blogs-card:hover .Blogs-trending-item {
  color: rgb(255, 253, 253); 
}

.TRENDING_BLOG_POST_CONTAINER{
  /* padding: 10px; */
  transition: color 0.3s ease, text-shadow 0.3s ease;
}
.Blogs-image-content-block{
  background: #edebed;
  padding: 10px;
cursor: pointer;
}
.Blogs-top-10
{
  transition: color 0.3s ease, text-shadow 0.3s ease;

}
.Blogs-top-10:hover {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: rgb(30, 30, 30);
}

.TRENDING_BLOG_DATE {
  font-weight: bold;
  margin: 0; 
  padding: 0; 
  line-height: 1.2; 
  color: rgb(105,65,198,1);
  font-size: 13px;
}
.Blogs-trending-card {
  transition: background-color 0.3s, color 0.3s;
}

.Blogs-trending-card:hover {
  background-color: rgb(73, 72, 72);
  color: black;
}

.Blogs-trending-card:hover .Blogs-card-content,
.Blogs-trending-card:hover .Blogs-trending-date,
.Blogs-trending-card:hover h3,
.Blogs-trending-card:hover p {
  color: rgb(244, 241, 241);
}
.Blogs-card-content{
  font-weight: bold;
  font-size: 20px;
  padding-top: 3px;
  
}
.Blogs-trending-heading{
font-weight: bold;
}
.Blogs-trending-date{
  font-weight: bold;
  padding-bottom: 10px;
}

.BLOGS_DECS{font-weight: 100;}



/* 123456789 */
/* 122345567863444 */
/* 434366556 */

.NEWSROOM_CARDS_CONTENT{
  font-weight: 100!important;
  font-size: 16px;
}

.Blogs-right-content-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px; 
  /* padding: 15px;  */
  /* border: 1px solid #ddd;  */
  /* border-radius: 8px;  */
  overflow: hidden; 
}

.Blogs-image-box-top {
  flex: 0 0 40%; 
  max-width: 40%; 
  overflow: hidden; 
}

.Blogs-image {
  width: 100%; 
  /* height: auto;  */
}

.Blogs-content-right-side {
  flex: 1; 
  padding: 10px; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
}

.Blogs-date-right {
  font-size: 14px; 
  color: rgb(105,65,198,1); 
}

.Blogs-top-10-right {
  font-size: 18px; 
  margin: 5px 0; 
}

.Blogs-right-content {
  font-size: 16px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .Blogs-right-content-block {
    flex-direction: column; 
  }

  .Blogs-image-box-top {
    max-width: 100%;
  }
}

.TRENDING_BLOG_POST_CONTAINER {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Blogs-image-content-block {
  display: flex;
  flex-direction: row;
  /* padding: 15px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  overflow: hidden;
}

.Blogs-new-left {
  flex: 0 0 40%;
  max-width: 40%;
}

.Trending-blog-image {
  width: 100%;
  height: auto;
}

.Blogs-new-right {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.TRENDING_BLOG_DATE {
  font-size: 14px;
  color: rgb(105,65,198,1);
}

.Blogs-new-right-heading {
  font-size: 25px;
  margin: 5px 0;
}

.Blogs-new-right-content {
  font-size: 16px;
  margin-top: 10px;
}

.Blogs-Trending-list {
  margin-top: 10px;
  display: flex;
  overflow-x: visible; 
  white-space: nowrap; 
}

.Blogs-trending-item {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .Blogs-image-content-block {
    flex-direction: column;
  }

  .Blogs-new-left {
    max-width: 100%;
  }
}

/* @media (max-width: 1200px){
  .Blogs-Trending-list{
    margin-top: -15px!important;
  }
} */

.Blogs-grid-row-Searching {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  margin: -10px;
}

.Blogs-card {
  flex: 1 1 calc(33.33% - 20px); 
  margin: 10px; 
  box-sizing: border-box; 
}


@media (max-width: 768px) {
  .Blogs-card {
    flex: 1 1 calc(50% - 20px); 
  }
}

@media (max-width: 480px) {
  .Blogs-card {
    flex: 1 1 100%; 
  }
}




@media (max-width: 1024px) {
  .Blogs-card {
    flex: 1 1 calc(50% - 20px); 
  }
  .BLOGS_OUTER_CONTAINER {
    margin-left: 3%;
    margin-right: 2%;
    margin-top: 8%;
}
.Blogs-blog-heading{
  font-size: 38px;
}
}

@media (max-width: 768px) {
  .Blogs-card {
    flex: 1 1 100%; 
  }
}

.Blogs-trending-date {
  font-size: 13px;
  /* color: black!important; */
}