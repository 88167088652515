.sub-heading-content {
  position: fixed;
  z-index: 9;
  top: 35vh;
  width: 20%;
  display: block;
  margin-left: 3%;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 4px 23.6px 0px rgba(0, 0, 0, 0.5);
}



.sub-heading-content .active {
  font-weight: 900;
}

.sub-heading {
  cursor: pointer;
}

.line-heading {
  width: 1%;
  border-radius: 2px;
  background: #009889;
}
.sub-heading {
  color: #009889;
  font-family: "TT Chocolates";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.8rem;
  cursor: pointer;
}
.follow {
  color: #555;
  font-family: "TT Chocolates";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 15%;
  margin-top: 5%;
  width: 80%;
}
.logos {
  display: flex;
  align-items: center;
  margin-left: 13%;
  margin-top: 5%;
  width: 75%;
  padding-bottom: 10%;
}
.logos2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.logos2 img {
  width: 90%;
}
.background-div {
  background: #fff;
  width: 80%;
  display: block;
  margin-left: 20%;
  padding-bottom: 3rem;
}
.background-div-left {
  background: #fff;
  width: 80%;
  display: block;
  margin-left: 0%;
  margin-top: 3rem;
  padding-bottom: 3rem;
}
.Competitve {
  margin-left: 5%;
}
.Free {
  margin-left: 30%;
  padding-top: 1rem;
  width: 70%;
}
.gapImage {
  display: block;
  gap: 3rem;
}
.gapImage2 {
  display: block;
  gap: 3rem;
}
.downArrow {
  position: relative;
  padding-left: 12rem;
}
.Integrity {
  margin-left: 24%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 70%;
}
.OurTeam {
  padding-top: 5%;
  margin-left: 40%;
  width: 50%;
  padding-bottom: 5%;
}
.WhoWe,
.OurServices,
.Competitve,
.TheAquarian {
  margin-left: 15%;
  width: 70%;
  padding-top: 4%;
}

.WhoWe{
  padding-top: 8%;
}

.Competitve .text-color h1 {
  text-align: center;
  margin-left: 0%;
}
.WhoWe h1,
.OurServices h1,
.OurTeam h1,
.Free h1,
.TheAquarian h1,
.Competitve h1,
.Integrity h1 {
  color: #000;
  font-family: "Goudy Old Style";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.WhoWe h3 {
  color: #000;
  font-family: "Goudy Old Style";
  font-size: 25px;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: normal;
}
.WhoWe .line,
.OurTeam .line,
.OurServices .line,
.TheAquarian .line,
.Competitve .line,
.Integrity .line, .about-us-heading .line {
  border-radius: 3.5px;
  background: #009889;
  height: 3px;
  width: 8rem;
}
.text-div {
  width: 100%;
  margin-left: 5%;
  margin-top: 2rem;
}
.OurServices .text-div,
.TheAquarian .text-div {
  width: 85%;
}
.gapImage img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.text-color2 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.35rem;
  width: 40%;
  margin-left: 35%;
}
.gapImage2 img {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.gapImage2 h1 {
  text-align: center;
}
.text-color {
  margin-top: 3rem;
  margin-left: 20%;
  width: 18rem;
  height: 18rem;
}
.text-color-expanded {
  position: absolute;
  margin-top: -16rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 25rem;
  height: 16rem;
  z-index: 9;
}
.text-color-expanded p {
  padding: 1.9rem;
  font-size: 14px;
}
.Integrity .text-color {
  width: 64%;
}
.text-div-color {
  background: #c2c2c2;
  padding-top: 3rem;
  margin-left: 3rem;
  padding-bottom: 3rem;
  width: 90%;
}
.WhoWe .text-div p1,
.Competitve .text-div p1,
.OurServices .text-div p1,
.OurTeam .text-div p1,
.TheAquarian .text-div p1,
.text-div-color p1 {
  color: #000;
  font-family: "TT Chocolates";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button {
  background: #01f9e1;
  height: auto;
  width: 35%;
  padding: 25px;
  text-align: center;
  color: #000;
  font-family: "Goudy Old Style";
  font-size: 22px;
  font-style: normal;
  border-radius: 10px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.button2 {
  background: #01f9e1;
  height: auto;
  padding: 18px;
  border-radius: 10px;
  width: 35%;
}
.team-background {
  display: flex;
  position: relative;
  background: #343436;
  margin-left: 40%;
  margin-bottom: 3rem;
  width: 50%;
  gap: 3rem;
}
.team-background-outer {
  display: flex;
  position: relative;
  background: #343436;
  margin-left: 32%;
  margin-top: 3rem;
  width: 40%;
  gap: 3rem;
}
.imageTeam {
  width: 30%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: -13%;
}
.textOurTeam {
  width: 70%;
  padding-top: 5%;
}
.flexgap {
  display: flex;
  gap: 20%;
}
.Name {
  color: #fff;
  font-family: "Goudy Old Style";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.designation {
  color: #d2d2d2;
  font-family: "Goudy Old Style";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about {
  color: #fff;
  font-family: "TT Chocolates";
  font-size: 19px;
  font-style: normal;

  font-weight: 400;
}
.image {
  height: 22rem;
}
.content {
  font-size: 55px;
  font-family: "freight-big-pro";
}
.below-image {
  background-color: #343436;
}
.text-div-below-image p1 {
  font-size: 20px;
}
.buttonBelow {
  background: #d84e40;
  height: auto;
  width: 70%;
  text-align: center;
  color: #fff;
  font-family: "Goudy Old Style";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.answer-content {
  transition: height 0.3s ease;
}
.WhoWe h5,
.Competitve h5,
.OurServices h5,
.OurTeam h5,
.TheAquarian h5 {
  color: #000;
  font-family: "Goudy Old Style";
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AddressHeading {
  color: aqua;
  font-size: large;
  margin-bottom: 3%;
}
@media (max-height: 700px) {
  .sub-heading-content {
    top: 40vh;
  }
}
@media (max-height: 600px) {
  .sub-heading-content {
    top: 45vh;
  }
}
@media (max-height: 500px) {
  .sub-heading-content {
    top: 50vh;
  }
}
@media (max-width: 1800px) {
  .button,
  .button2 {
    font-size: 17px;
  }
}
@media (max-width: 1700px) {
  .sub-heading {
    font-size: 19px;
  }
  .WhoWe .text-div p1,
  .OurServices .text-div p1,
  .TheAquarian .text-div p1,
  .Competitve .text-div p1,
  .OurTeam .text-div p1,
  .text-div-color p1 {
    font-size: 20px;
  }
  .follow {
    font-size: 24px;
  }
  .text-color p {
    font-size: 19px;
  }
  .imageTeam {
    width: 35%;
  }
  .WhoWe h1,
  .Free h1,
  .OurServices h1,
  .OurTeam h1,
  .TheAquarian h1,
  .Competitve h1,
  .Integrity h1 {
    font-size: 28px;
  }

  .Name {
    font-size: 32px;
  }
  .designation {
    font-size: 24px;
  }
  .about {
    padding-right: 1rem;
    font-size: 18px;
  }
  .image {
    height: 20rem;
  }
  .text-color {
    width: 14rem;
    height: 14rem;
  }
  .text-color-expanded {
    width: 22rem;
    height: 16rem;
    z-index: 9;
    margin-left: -1.5rem;
    margin-top: -16rem;
  }
  .text-color-expanded p {
    padding: 2rem;
    font-size: 12.5px;
  }
  .downArrow {
    padding-left: 9.5rem;
  }
  .content {
    font-size: 52px;
  }
  .button,
  .button2 {
    width: 40%;
  }
}
@media (max-width: 1590px) {
  .sub-heading {
    font-size: 17px;
  }
  .WhoWe .text-div p1,
  .OurServices .text-div p1,
  .OurTeam .text-div p1,
  .Competitve .text-div p1,
  .TheAquarian .text-div p1,
  .text-div-color p1 {
    font-size: 18px;
  }
  .follow {
    font-size: 22px;
  }
  .imageTeam {
    width: 38%;
  }
  .text-color p {
    font-size: 18.5px;
  }

  .WhoWe h3 {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .Name {
    font-size: 30px;
  }
  .designation {
    font-size: 22px;
  }
  .about {
    font-size: 17px;
  }
  .button,
  .button2 {
    width: 45%;
    font-size: 19px;
  }
  .image {
    height: 18rem;
  }
  .content {
    font-size: 48px;
  }
}

@media (max-width: 1400px) {
  .sub-heading {
    font-size: 17px;
  }
  .text-color p {
    font-size: 18px;
  }
  .follow {
    font-size: 22px;
  }

  .imageTeam {
    width: 45%;
  }
  .WhoWe h1,
  .Competitve h1,
  .OurServices h1,
  .OurTeam h1,
  .TheAquarian h1,
  .Free h1,
  .Integrity h1 {
    font-size: 26px;
  }
  .button,
  .button2 {
    font-size: 18px;
    width: 50%;
  }
  .image {
    height: 17rem;
  }
  .content {
    font-size: 45px;
  }
  .about {
    font-size: 16px;
  }
}
@media (max-width: 1350px) {
  .button,
  .button2 {
    font-size: 16px;
    width: 65%;
  }
}
@media (max-width: 1220px) {
  .WhoWe h1,
  .Competitve h1,
  .OurServices h1,
  .OurTeam h1,
  .TheAquarian h1,
  .Free h1,
  .Integrity h1 {
    font-size: 24px;
  }
  .imageTeam {
    width: 47%;
  }
  .button,
  .button2 {
    font-size: 14px;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .sub-heading-content {
    display: none;
  }
}
@media (max-width: 768px) {
  
  .text-color p {
    font-size: 16px;
  }
  .flexgap {
    display: block;
    gap: 0%;
  }
  .WhoWe h1,
  .Competitve h1,
  .OurServices h1,
  .OurTeam h1,
  .TheAquarian h1,
  .Free h1,
  .Integrity h1 {
    font-size: 20px;
  }

  .background-div {
    background: none;
    width: 100%;
    margin-left: 0%;
    padding-bottom: 0rem;
  }
  .background-div-left {
    background: none;
    width: 100%;
    margin-right: 0%;
    margin-top: 0rem;
    padding-bottom: 0rem;
  }
  .WhoWe .line,
  .OurServices .line,
  .Competitve .line,
  .TheAquarian .line,
  .OurTeam .line,
  .Integrity .line {
    width: 4rem;
  }
  .text-div-color img {
    width: 30%;
  }
  .text-div-color {
    width: 90%;
    margin-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .text-color,
  .Integrity .text-color {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .text-color2 {
    margin-top: 0.35rem;
    width: 100%;
    margin-left: 0%;
  }

  .Integrity {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .Free {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0rem;
    width: 90%;
  }
  .Competitve {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .gapImage {
    gap: 1rem;
  }
  .gapImage2 {
    display: block;
  }
  .gapImage2 img {
    margin-left: auto;
    margin-right: auto;
  }

  .downArrow {
    margin-left: 10.5rem;
  }
  .WhoWe,
  .OurServices,
  .TheAquarian {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .button {
    width: 100%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  .logos2 img {
    width: 95%;
  }
  .button2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  .OurTeam {
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-bottom: 2rem;
  }
  .text-div {
    width: 95%;
  }
  .OurServices .text-div,
  .TheAquarian .text-div {
    width: 95%;
  }
  .team-background {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3rem;
    width: 95%;
  }
  .team-background-outer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    width: 95%;
  }
  .WhoWe h5,
  .Competitve h5,
  .OurServices h5,
  .OurTeam h5,
  .TheAquarian h5 {
    font-size: 30px;
  }
  .imageTeam {
    width: 70%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .Name {
    text-align: center;
  }
  .designation {
    text-align: center;
  }
  .about {
    text-align: center;
  }
  .textOurTeam {
    width: 90%;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}
