.contact-use-address-menu{
  width: 450px;
  position: relative;
}
.address-content {
  position: sticky;
  z-index: 9;
  top: 12vh;
  width: 90%;
  display: block;
  margin-left: 10%;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 4px 23.6px 0px rgba(0, 0, 0, 0.5);
}

.form-contact-us{
  margin-right: auto;
  margin-left: auto;
  max-width: 2000px;
  width: 1000px;
}

.contact-us-address-wraper{
  padding: 2rem;
}

.address-heading {
  color: #009889;
  font-family: "TT Chocolates";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block-end: 0.5em;
}
.logosContact {
  display: flex;
  align-items: center;
}

.registration-form{
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  padding-right: 7rem;
  margin-bottom: 5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 1.5rem;
}
.form-row .form-group,
.form-row .form-date {
  margin-bottom: 20px;
}

.form-date .react-datepicker-wrapper{
  width: 100%;
}

.form-row label {
  display: block;
  margin-bottom: 10px;
}

.form-row input,
.form-row select {
  max-width: 23rem;
  width: 100%;
  padding: 10px;
  border: 1px solid #009889;
  border-radius: 5px;
}

.form-row .error {
  border-color: #b90124;
  background-color: "#ffe6e6";
}
.registration-form .submit-btn {
  background-color: #009889;
  color: #fff;
  padding: 10px 60px;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.error-message {
  color: #b90124;
  font-size: 12;
}

.form-date input {
  padding-left: 1rem;
}

@media (max-width: 768px) {

  .form-contact-us{
    width: 100%;
  }

  .registration-form {
    width: 90%;
    display: block;
    padding-right: 0;
    margin-left: 2rem;
    padding-right: 2rem;
  }

  .form-row {
    display: block;
    justify-content: gap-between;
    gap: 20%;
  }
  .ContactUsHeader .header-content {
    padding-top: 25%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
    width: 50%;
  }
  .ContactUsHeader {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .form-row input,
  .form-row select,
  .form-row .error {
    max-width: 40rem;
    width: 100%;
  }

  .address-content {
    position: relative;
    top: 0px;
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
}
